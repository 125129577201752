import Link from "next/link";
import {NOT_FOUND_META, NOT_FOUND_TITLE} from "../core-web/constants";
import {WebsiteLayout} from "../core-web/components/landing/WebsiteLayout";
import {PageHead} from "../core-web/components/core/templates/PageHead";
import Footer from "../core-web/components/landing/molecules/Footer";
import Navigation from "../core-web/components/landing/molecules/Navigation";
import {Box} from "@chakra-ui/react";

export default function Custom404() {
  return <PageHead title={NOT_FOUND_TITLE}
    description={NOT_FOUND_META}>
    <WebsiteLayout>
      <Navigation/>
      <Box minH={"75vh"} display={"flex"} alignItems={"center"}>
        <div className={"h-[60%] max-w-2xl mx-auto space-y-8 my-16 flex items-center flex-col"}>
          <div className={"text-4xl font-bold "}>Page not found</div>

          <div className={"text-lg text-gray-700 px-16"}>We cannot find anything at this location! Please check the
            URL and make sure it is correct.
          </div>

          <Link href={"/"} legacyBehavior>
            <div
              className={"text-lg w-48 text-center px-6 py-3 rounded-lg font-medium bg-blue-500 text-white cursor-pointer hover:bg-blue-600 active:bg-blue-700"}>
              To main page
            </div>
          </Link>
        </div>

      </Box>
      <Footer/>

    </WebsiteLayout>

  </PageHead>
}
